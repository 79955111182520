/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 11, 2023 */

/* fira sans */
@font-face {
    font-family: 'fira_sansregular';
    src: url('./firasans-regular.woff2') format('woff2'),
         url('./firasans-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'fira_sansmedium';
    src: url('./firasans-medium.woff2') format('woff2'),
         url('./firasans-medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'fira_sanssemibold';
    src: url('firasans-semibold.woff2') format('woff2'),
         url('firasans-semibold.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'fira_sansbold';
    src: url('firasans-bold.woff2') format('woff2'),
         url('firasans-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


/* fira sans condensed */

@font-face {
    font-family: 'fira_sans_condensedregular';
    src: url('./firasanscondensed-regular.woff2') format('woff2'),
         url('./firasanscondensed-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'fira_sans_condensedmedium';
    src: url('./firasanscondensed-medium.woff2') format('woff2'),
         url('./firasanscondensed-medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'fira_sans_condensedbold';
    src: url('./firasanscondensed-bold.woff2') format('woff2'),
         url('./firasanscondensed-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

/* public sans */

@font-face {
    font-family: 'public_sansregular';
    src: url('./public-sans.regular.woff2') format('woff2'),
         url('./public-sans.regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'public_sansmedium';
    src: url('./public-sans.medium.woff2') format('woff2'),
         url('./public-sans.medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'public_sansbold';
    src: url('./public-sans.bold.woff2') format('woff2'),
         url('./public-sans.bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'public_sanssemibold';
    src: url('./public-sans.semibold.woff2') format('woff2'),
         url('./public-sans.semibold.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}